import React from "react"
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Router } from "@reach/router"
import { Link } from 'react-router-dom'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero1 from "../components/Hero/Hero1"
import Recognition from "../components/Recognition"
import HyperAutomation from "../components/HyperAutomation"
import Container from "../components/ui/Container"
import Card1 from "../components/Card/Card1"
import { StaticImage } from "gatsby-plugin-image"
import Cta1 from "../components/Cta/Cta1"
import { useState } from "react";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
 
  <Container>
        
    <div className="grid lg:grid-cols-2 sm:grid-cols-2  gap-14 mt-21 place-items-center mb-[7rem]">
    <div className="items-end">
    <span className="mtext">Hospitality and Real Estate</span>
            <h1 className="hm50">
               
             Achieve Peak efficiencies and maximum productivity in hospitality accounting operations
            </h1>
            <p className="text-gray-700 text-base mtr1">
            Complete Hyperautomation offering to help you scale the back office operations and eliminate drudgery.
            </p>
            <button className="bg-transparent bg-red-500 text-white-700 font-semibold hover:text-white blogbtn2 border border-blue-500 hover:border-transparent rounded">
              Read More
            </button>&emsp;
            <button className="bg-transparent bg-red-500 text-white-700 font-semibold hover:text-white blogbtn2 border border-blue-500 hover:border-transparent rounded">
            Explore Usecases
            </button>
          </div>
          <div className="items-end ">
            <img
              className="blogimg "
              src="/images/mask.png"
              alt="Sunset in the mountains"
            />
          </div>
         
        
        </div>
        </Container>
        <Container>
        <div className="grid lg:grid-cols-4 grid-cols-2  gap-4 place-items-center  lg:divide-x-[2px] lg:shadow-lg mt-[-5rem] p-[1rem]">
         <div>
       <img src="images/aicpa.png"/>
        
         
         </div>
             
         

         <div>
            
         <div className="ctext count-up"><span  className="hm8  count"></span><span className="hm9"> M+</span></div>
         <p className="pctext ml-[2rem]">Content pieces Processed</p>
         </div>
         <div>
         <div className="ctext"><span className="hm8">25</span><span className="hm9"> M+</span></div>
         <p className="pctext ml-[2rem]">Processed Iterations</p>
         </div>
         <div>
         <div className="ctext "><span className="hm8">100%</span></div>
         <p className="pctext ml-[2rem]">of Combined Experience</p>
         </div>
         

          
            
          
         
        
        </div>  
        <img className="mt-[7rem]" src="/images/hbg.png"/ >
        </Container>
<br/>

<Container>

<h1 className="hm10 mt-[3rem]">Ultra-flexible, ultra-scalable platform that knows no limits.
</h1>
<p>Hyperautomation is changing the way how hospitality industry operates and performs. Eliminate delays leading to faster turnaround time, ensuring timeliness of critical accounting activities such as accounts receivable & payable tasks, general Ledger entries, bank reconciliations and reporting with AI-automation. Our AI-enabled, content intelligent platform, RAPFlow has the capability to improve business processes through intelligent automation. RAPFlow automates processes with structured data extraction from unstructured content. Using RAPFlow in tandem with RAPBot, an RPA tool, allows for superior workflow automation.</p>
    



    
            <div className="  ">
                {/* Carousel for desktop and large size devices */}
                <CarouselProvider className="mb-[7rem] lg:block hidden" naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={12} visibleSlides={4} step={1} infinite={true}>
                    <div className="w-full relative flex items-center justify-center">
                        <ButtonBack role="button" aria-label="slide backward" className="mb-[-21rem] absolute z-30 left-0 ml-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer" id="prev">
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="black" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBack>
                        <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                            <Slider>
                                <div id="slider" className="h-full flex lg:gap-28 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                                    <Slide index={0}>
                                        
                                        <div className="p-[1rem]   ">
  
  <div className=" py-4 pr-[1.5rem]">
    <div className="font-bold text-xl mb-2">The Coldest Sunset</div>
    <p className="text-gray-700 text-base">
sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    </p>
  </div>

</div>
                                        
                                    </Slide>
                                    <Slide index={1}>
                                
                                        <div className=" p-[1rem]   shadow-lg rmcard">
  <img className="" src="/images/frame.png" alt="Sunset in the mountains"/>
  <div className=" py-4 pr-[1.5rem]">
    <div className="font-bold text-xl mb-2">The Coldest Sunset</div>
    <p className="text-gray-700 text-base">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vo exercitationem praesentium nihil.
    </p>
  

</div>
                                        </div>
                                    </Slide>
                                    <Slide index={2}>
                                        
                                        <div className=" p-[1rem]   shadow-lg rmcard">
  <img className="" src="/images/frame.png" alt="Sunset in the mountains"/>
  <div className=" py-4 pr-[1.5rem]">
    <div className="font-bold text-xl mb-2">The Coldest Sunset</div>
    <p className="text-gray-700 text-base">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vo exercitationem praesentium nihil.
    </p>
  </div>

</div>
                                        
                                    </Slide>
                                    <Slide index={3}>
                                        
                                        <div className=" p-[1rem]   shadow-lg rmcard">
  <img className="" src="/images/frame.png" alt="Sunset in the mountains"/>
  <div className=" py-4 pr-[1.5rem]">
    <div className="font-bold text-xl mb-2">The Coldest Sunset</div>
    <p className="text-gray-700 text-base">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vo exercitationem praesentium nihil.
    </p>
  </div>

</div>
                                        
                                    </Slide>
                                    <Slide index={4}>
                                    <div className=" p-[1rem]   shadow-lg rmcard">
  <img className="" src="/images/frame.png" alt="Sunset in the mountains"/>
  <div className=" py-4 pr-[1.5rem]">
    <div className="font-bold text-xl mb-2">The Coldest Sunset</div>
    <p className="text-gray-700 text-base">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vo exercitationem praesentium nihil.
    </p>
  </div>

</div>
                                    </Slide>
                                    <Slide index={5}>
                                    <div className="  p-[1rem]  shadow-lg rmcard">
  <img className="" src="/images/frame.png" alt="Sunset in the mountains"/>
  <div className=" py-4 pr-[1.5rem]">
    <div className="font-bold text-xl mb-2">The Coldest Sunset</div>
    <p className="text-gray-700 text-base">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vo exercitationem praesentium nihil.
    </p>
  </div>

</div>
                                    </Slide>
                                    

                                   
                                
                                  
                                
                               
                                
                                </div>
                            </Slider>
                        </div>
                        <ButtonNext role="button" aria-label="slide forward" className=" mb-[-21rem] absolute z-30 right-0 lg:mr-[73rem] focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400" id="next">
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="black" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonNext>
                    </div>
                </CarouselProvider>

                {/* Carousel for tablet and medium size devices */}
                <CarouselProvider className="lg:hidden md:block hidden" naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={12} visibleSlides={2} step={1} infinite={true}>
                    <div className="w-full relative flex items-center justify-center">
                        <ButtonBack role="button" aria-label="slide backward" className="absolute z-30 left-0 ml-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer" id="prev">
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBack>
                        <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                            <Slider>
                                <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                                    <Slide index={0}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src="https://i.ibb.co/fDngH9G/carosel-1.png" alt="black chair and white table" className="object-cover object-center w-full" />
                                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                                                <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 1</h2>
                                                <div className="flex h-full items-end pb-6">
                                                    <h3 className="text-xl lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Minimal Interior</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={1}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src="https://i.ibb.co/DWrGxX6/carosel-2.png" alt="sitting area" className="object-cover object-center w-full" />
                                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                                                <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 2</h2>
                                                <div className="flex h-full items-end pb-6">
                                                    <h3 className="text-xl lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Minimal Interior</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={2}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src="https://i.ibb.co/tCfVky2/carosel-3.png" alt="sitting area" className="object-cover object-center w-full" />
                                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                                                <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 2</h2>
                                                <div className="flex h-full items-end pb-6">
                                                    <h3 className="text-xl lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Minimal Interior</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={3}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src="https://i.ibb.co/rFsGfr5/carosel-4.png" alt="sitting area" className="object-cover object-center w-full" />
                                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                                                <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 2</h2>
                                                <div className="flex h-full items-end pb-6">
                                                    <h3 className="text-xl lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Minimal Interior</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={4}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src="https://i.ibb.co/fDngH9G/carosel-1.png" alt="black chair and white table" className="object-cover object-center w-full" />
                                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                                                <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 2</h2>
                                                <div className="flex h-full items-end pb-6">
                                                    <h3 className="text-xl lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Minimal Interior</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={5}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src="https://i.ibb.co/DWrGxX6/carosel-2.png" alt="sitting area" className="object-cover object-center w-full" />
                                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                                                <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 2</h2>
                                                <div className="flex h-full items-end pb-6">
                                                    <h3 className="text-xl lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Minimal Interior</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={6}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src="https://i.ibb.co/tCfVky2/carosel-3.png" alt="sitting area" className="object-cover object-center w-full" />
                                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                                                <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 2</h2>
                                                <div className="flex h-full items-end pb-6">
                                                    <h3 className="text-xl lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Minimal Interior</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={7}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src="https://i.ibb.co/rFsGfr5/carosel-4.png" alt="sitting area" className="object-cover object-center w-full" />
                                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                                                <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 2</h2>
                                                <div className="flex h-full items-end pb-6">
                                                    <h3 className="text-xl lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Minimal Interior</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={8}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src="https://i.ibb.co/fDngH9G/carosel-1.png" alt="black chair and white table" className="object-cover object-center w-full" />
                                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                                                <h2 className="texlg:t-xl le leading-4 text-basealg:ding-tight text-white">Catalog 2</h2>
                                                <div className="flex h-full items-end pb-6">
                                                    <h3 className="text-xl lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Minimal Interior</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={9}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src="https://i.ibb.co/DWrGxX6/carosel-2.png" alt="sitting area" className="object-cover object-center w-full" />
                                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                                                <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 2</h2>
                                                <div className="flex h-full items-end pb-6">
                                                    <h3 className="text-xl lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Minimal Interior</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={10}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src="https://i.ibb.co/tCfVky2/carosel-3.png" alt="sitting area" className="object-cover object-center w-full" />
                                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                                                <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 2</h2>
                                                <div className="flex h-full items-end pb-6">
                                                    <h3 className="text-xl lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Minimal Interior</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={11}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src="https://i.ibb.co/rFsGfr5/carosel-4.png" alt="sitting area" className="object-cover object-center w-full" />
                                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                                                <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 2</h2>
                                                <div className="flex h-full items-end pb-6">
                                                    <h3 className="text-xl lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Minimal Interior</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                </div>
                            </Slider>
                        </div>
                        <ButtonNext role="button" aria-label="slide forward" className="absolute z-30 right-0 mr-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400" id="next">
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonNext>
                    </div>
                </CarouselProvider>

                {/* Carousel for mobile and Small size Devices */}
                <CarouselProvider className="block md:hidden " naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={12} visibleSlides={1} step={1} infinite={true}>
                    <div className="w-full relative flex items-center justify-center">
                        <ButtonBack role="button" aria-label="slide backward" className="absolute z-30 left-0 ml-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer" id="prev">
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBack>
                        <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                            <Slider>
                                <div id="slider" className="h-full w-full flex lg:gap-8 md:gap-6 items-center justify-start transition ease-out duration-700">
                                    <Slide index={0}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src="https://i.ibb.co/fDngH9G/carosel-1.png" alt="black chair and white table" className="object-cover object-center w-full" />
                                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                                                <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 1</h2>
                                                <div className="flex h-full items-end pb-6">
                                                    <h3 className="text-xl lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Minimal Interior</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={1}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src="https://i.ibb.co/DWrGxX6/carosel-2.png" alt="sitting area" className="object-cover object-center w-full" />
                                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                                                <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 2</h2>
                                                <div className="flex h-full items-end pb-6">
                                                    <h3 className="text-xl lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Minimal Interior</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={2}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src="https://i.ibb.co/tCfVky2/carosel-3.png" alt="sitting area" className="object-cover object-center w-full" />
                                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                                                <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 2</h2>
                                                <div className="flex h-full items-end pb-6">
                                                    <h3 className="text-xl lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Minimal Interior</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={3}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src="https://i.ibb.co/rFsGfr5/carosel-4.png" alt="sitting area" className="object-cover object-center w-full" />
                                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                                                <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 2</h2>
                                                <div className="flex h-full items-end pb-6">
                                                    <h3 className="text-xl lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Minimal Interior</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={4}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src="https://i.ibb.co/fDngH9G/carosel-1.png" alt="black chair and white table" className="object-cover object-center w-full" />
                                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                                                <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 2</h2>
                                                <div className="flex h-full items-end pb-6">
                                                    <h3 className="text-xl lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Minimal Interior</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={5}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src="https://i.ibb.co/DWrGxX6/carosel-2.png" alt="sitting area" className="object-cover object-center w-full" />
                                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                                                <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 2</h2>
                                                <div className="flex h-full items-end pb-6">
                                                    <h3 className="text-xl lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Minimal Interior</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={6}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src="https://i.ibb.co/tCfVky2/carosel-3.png" alt="sitting area" className="object-cover object-center w-full" />
                                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                                                <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 2</h2>
                                                <div className="flex h-full items-end pb-6">
                                                    <h3 className="text-xl lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Minimal Interior</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={7}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src="https://i.ibb.co/rFsGfr5/carosel-4.png" alt="sitting area" className="object-cover object-center w-full" />
                                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                                                <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 2</h2>
                                                <div className="flex h-full items-end pb-6">
                                                    <h3 className="text-xl lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Minimal Interior</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={8}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src="https://i.ibb.co/fDngH9G/carosel-1.png" alt="black chair and white table" className="object-cover object-center w-full" />
                                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                                                <h2 className="texlg:t-xl le leading-4 text-basealg:ding-tight text-white">Catalog 2</h2>
                                                <div className="flex h-full items-end pb-6">
                                                    <h3 className="text-xl lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Minimal Interior</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={9}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src="https://i.ibb.co/DWrGxX6/carosel-2.png" alt="sitting area" className="object-cover object-center w-full" />
                                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                                                <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 2</h2>
                                                <div className="flex h-full items-end pb-6">
                                                    <h3 className="text-xl lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Minimal Interior</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={10}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src="https://i.ibb.co/tCfVky2/carosel-3.png" alt="sitting area" className="object-cover object-center w-full" />
                                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                                                <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 2</h2>
                                                <div className="flex h-full items-end pb-6">
                                                    <h3 className="text-xl lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Minimal Interior</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={11}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src="https://i.ibb.co/rFsGfr5/carosel-4.png" alt="sitting area" className="object-cover object-center w-full" />
                                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                                                <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 2</h2>
                                                <div className="flex h-full items-end pb-6">
                                                    <h3 className="text-xl lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Minimal Interior</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                </div>
                            </Slider>
                        </div>
                        <ButtonNext role="button" aria-label="slide forward" className="absolute z-30 right-0 mr-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400" id="next">
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonNext>
                    </div>
                </CarouselProvider>
            </div>
        

            <div className="grid lg:grid-cols-2 lg:gap-4 mt-[2rem]">
<div className=" bgyellow    webcard  ">
  
  <div className="flow-root">
	   <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
		   <li>
			   <div className="flex items-center space-x-4">
				   <div className="flex-shrink-0">
					   <img className="w-[6rem]  rounded-full" src="/images/Group-1.png" alt="Neil image"/>
				   </div>
				   <div className="flex-1 min-w-0 p-[1rem]">
					 <h1 className="webtitle1">Automate Any Task </h1>
					   <p className="webp g1 text-sm font-medium">
					   Mimic human action to copy and paste data, read and write to interfaces, make calculations and more. The RAPBot also performs back-end actions like calling an API or reading/writing to a database.</p>
					  
				   </div>
				  
			   </div>
		   </li>
		 
		 
		 
		 
	   </ul>
  </div>
</div>
<div className=" bgred    webcard  bggreen ">
  
   <div className="flow-root">
        <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
            <li>
                <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                        <img className="w-[6rem]  rounded-full" src="/images/Group-2.png" alt="Neil image"/>
                    </div>
                    <div className="flex-1 min-w-0 p-[1rem]">
                      <h1 className="webtitle1">Boost Operational Efficiency</h1>
                        <p className="webp g1 text-sm font-medium ">
                        RAPBot’s automation accelerates processes, cuts costs and minimizes errors providing greater operational efficiency.</p>
                       
                    </div>
                   
                </div>
            </li>
          
          
          
          
        </ul>
   </div>
</div>
<div className="     webcard bgviolet ">
  
   <div className="flow-root">
        <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
            <li>
                <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                        <img className="w-[6rem]  rounded-full" src="/images/Group-3.png" alt="Neil image"/>
                    </div>
                    <div className="flex-1 min-w-0 p-[1rem]">
                      <h1 className="webtitle1">Rapid ROI & Low TCA</h1>
                        <p className="webp g1 text-sm font-medium ">
						Automation enables up to 70% reduction in processing costs which leads to rapid ROI. Our simple licensing model reduces total cost of automation significantly.</p>
                       
                    </div>
                   
                </div>
            </li>
          
          
          
          
        </ul>
   </div>
</div>
<div className="     webcard bgpink ">
  
   <div className="flow-root">
        <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
            <li>
                <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                        <img className="w-[6rem]  rounded-full" src="/images/Group-4.png" alt="Neil image"/>
                    </div>
                    <div className="flex-1 min-w-0 p-[1rem]">
                      <h1 className="webtitle1">Unlimited Scalability</h1>
                        <p className="webp g1 text-sm font-medium ">
                        The sky is the limit. Actually that’s not true, there is no limit on scaling operations with RAPBot. Deploy across platforms – desktop, cloud, server or a virtual machine.</p>
                       
                    </div>
                   
                </div>
            </li>
          
          
          
          
        </ul>
   </div>
</div>


</div>

<div className="mt-[7rem]">
<Swiper navigation={true} modules={[Navigation]} className="mySwiper">
  
       
  <SwiperSlide>
  <div className="flex flex-col  ">
<div
  className=" flex rounded-xl shadow-lg p-3 max-w-xs md:max-w-3xl  border border-white bg-white">

      <div className="text-center aligh-center p-[1rem]">
          
      
<p className="">The best kept secret of The Bahamas is the country’s sheersize and diversity. With 16 major islands, The Bahamas is an unmatched destination</p>
              <h3 className="">The Majestic </h3>
              <h3 className="hm27"> Bahamas</h3>
      </div>
  </div>
</div>

  </SwiperSlide>
  <SwiperSlide>
  <div className="flex flex-col  ">
<div
  className=" flex rounded-xl shadow-lg p-3 max-w-xs md:max-w-3xl  border border-white bg-white">

      <div className="w-full md:w-2/3 p-[1rem]">
          
      
<p className="">The best kept secret of The Bahamas is the country’s sheersize and diversity. With 16 major islands, The Bahamas is an unmatched destination</p>
              <h3 className="hm26">The Majestic and Wonderful Bahamas</h3>
              <h3 className="hm27">The Majestic and Wonderful Bahamas</h3>
      </div>
  </div>
</div>

  </SwiperSlide>
  <SwiperSlide>
  <div className="flex flex-col  ">
<div
  className=" flex rounded-xl shadow-lg p-3 max-w-xs md:max-w-3xl  border border-white bg-white">

      <div className="w-full md:w-2/3  p-[1rem]">
          
      
<p className="">The best kept secret of The Bahamas is the country’s sheersize and diversity. With 16 major islands, The Bahamas is an unmatched destination</p>
              <h3 className="hm26">The Majestic and Wonderful Bahamas</h3>
              <h3 className="hm27">The Majestic and Wonderful Bahamas</h3>
      </div>
  </div>
</div>

  </SwiperSlide>
</Swiper>




</div>

<h1 className="hm29 mt-[7rem]">Breaking productivity barriers one usecase at a time</h1><br/>

<div className="grid lg:grid-cols-3 md:grid-cols-2 webv1  gap-4 place-items-center pad1rr">
  

  <div>  <a href="#">
        <img className="rounded-t-lg hm23" src="/images/tech.png" alt=""/>
    </a>
<div className="hm24">
  
    <div className="p-5">
        <a href="#">
            <h5 className="hm30">First State Bank
Mortgage</h5>
        </a>
        <p className="hm31">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
        <p className="hm32">
        Read the Story &nbsp; <i className="fa fa-long-arrow-right"></i>
           </p>
    </div>
</div>
</div>
<div>  <a href="#">
        <img className="rounded-t-lg hm23" src="/images/tech.png" alt=""/>
    </a>
<div className="hm24">
  
    <div className="p-5">
        <a href="#">
            <h5 className="hm30">First State Bank
Mortgage</h5>
        </a>
        <p className="hm31">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
        <p className="hm32">
        Read the Story &nbsp; <i className="fa fa-long-arrow-right"></i>
           </p>
    </div>
</div>
</div>
<div>  <a href="#">
        <img className="rounded-t-lg hm23" src="/images/tech.png" alt=""/>
    </a>
<div className="hm24">
  
    <div className="p-5">
        <a href="#">
            <h5 className="hm30">First State Bank
Mortgage</h5>
        </a>
        <p className="hm31">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
        <p className="hm32">
        Read the Story &nbsp; <i className="fa fa-long-arrow-right"></i>
           </p>
    </div>
</div>
</div>

</div>
<div className="flex items-center justify-center mt-[2rem]">
          <button className="bg-transparent bg-red-500 text-white-700 font-semibold hover:text-white blogbtn2 border border-blue-500 hover:border-transparent rounded">Explore More</button>
        
                </div>
  </Container>


  


    <div className="grid lg:grid-cols-2 sm:grid-cols-2  gap-14 mt-21 place-items-center mb-[7rem]">
    <div className="items-end ">
            <img
              className="blogimg "
              src="/images/mask.png"
              alt="Sunset in the mountains"
            />
          </div>
   
    <div className="items-end">
    <span className="mtext">Hospitality and Real Estate</span>
            <h1 className="hm50">
               
             Achieve Peak efficiencies and maximum productivity in hospitality accounting operations
            </h1>
            <p className="text-gray-700 text-base mtr1">
            Complete Hyperautomation offering to help you scale the back office operations and eliminate drudgery.
            </p>
            <button className="bg-transparent bg-red-500 text-white-700 font-semibold hover:text-white blogbtn2 border border-blue-500 hover:border-transparent rounded">
              Read More
            </button>&emsp;
           
          </div>
       
         
        
        </div>
        <div className="grid lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-4  mf1 p-[3rem]">
<div>
            <h1 className="mf">
            Automate Processes using AI for speed, efficiency and savings
            </h1>
         
        
          
          </div>

          
<div className="lg:ml-[30rem] lg:mt-[-1rem] md:ml-[5rem]">
<button className="mf3 rounded text-red">
            Book a Demo
            </button>
</div>
    </div>
  </Layout>
)

export default IndexPage
